<template lang="">
    <div class="inspect">
        <div class="header">
             <span @click="previousPage()"></span>
             <span>完备检查</span>
             <span @click="LogOut()">退出</span>
        </div>
        <div class="inspect_main">
            <p>备注：</p>
            <textarea name="" id="" v-model="remark" style="border:1px solid #eee"></textarea>
            <div class="btn_list">
                <span :class="['genre',now == item.id ? 'cur' : '']" v-for="(item,index) in CarClassIf" :key="item.id" @click="changHan(item,index)" >{{item.name}}
                </span>
            </div>
            <div class="table" >
                <van-row gutter="20" class="table_row" v-for="(item, index) in list[now]" :key="index" >
                    
                    <van-col span="6" class="table_row_col1">
                        <input class="input_van" v-model="item.name"  placeholder="拆车件名称"/>
                    </van-col>
                    <van-col span="6" class="table_row_col2">
                        <input type="number" v-model="item.num" class="input_van"  placeholder="拆车件数量"/>
                    </van-col>
                    <van-col span="12" class="table_row_col3">
                        <van-icon name="cross" class="cha" @click="coldes(item,index)" />
                        <van-radio-group class="table_col_radio" v-model="item.isDestroy" direction="horizontal">
                            <van-radio :name='Number(0)'>未破损</van-radio>
                            <van-radio :name='Number(1)'>已破损</van-radio>
                        </van-radio-group>
                    </van-col>
                </van-row>
                <div class="icon"> 
                    <van-icon @click="addList" name="plus" />
                </div>
            </div>
        </div>
        <div class="batton">
            <van-button class="batton_btn" @click="setData" type="info">提交{{Niname}}</van-button>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import {  setCookie , deleteCookie} from '../../utils/env'
    export default {
        data() {
            return {
                CarClassIf:[],//类型数据
                now:0,//当前点击的类型
                idx:0,//当前点击
                totalList:[],//列表集合
                database:[],//最后接口提交的集合
                Niname:'',
                remark:"",
                arrList:[],
                list: {},//列表
                    
               
            }
        },
      
        created () {
            this.lodData();
            // this.setData();

            this.$http.post("/index.php/index/Car_Information/completenessCheckGet",{end_of_lifeVehiclesId:this.$route.query.ids}).then(res=>{
                //console.log(res.data.data.data)
                this.arrList = res.data.data.data
                this.arrList.forEach((i)=>{
                  
                    if(i.data.length!=0){
                        //console.log(i.data)
                        i.data.forEach(j=>{
                            let obj = {
                                'name':j.name,//名称
                                'num':j.num,//数量
                                'isDestroy':j.isDestroy,//单选按钮
                            }
                            this.list[j.carClassifyId].push(obj)
                          
                            //console.log(this.list[j.carClassifyId],j,this.list)
                        })
                    }
                })
                
                this.remark = res.data.data.remark
            })
        },
        methods: {
            coldes(item,index){
                this.list[this.now].splice(index,1)
            },
            LogOut(){
                this.$http.post('/index.php/index/login_Out').then(res=>{
                        deleteCookie('token')
                        this.$toast.success(res.data.msg)
                        // window.localStorage.removeItem('')
                        this.$router.push('/login')
                        //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                 
                        //     if(res.data.code == 0){
                        //         let domain = this.zhuan(res.data.data)
                        //         let url = window.location.origin
                           
                        //         window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
                        //     }
                        // })
                        // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
                        // this.$router.push('/')
                        
                    })
                },
                 zhuan(url){
                        let str = url
                        let str1 = str.replace('https','')
                        str1 = str1.replace('http','')
                        let str2 = 'https'+ str1
                        return str2
                    },
            lodData() {// 所有简单分类
                this.$http.post('/index.php/index/Simply',{'type':'carClassify'}).then(res=>{
                    if(res.data.code==0){
                        this.CarClassIf = res.data.data
                        ////console.log(res.data.data)
                        this.Niname = res.data.data[0].name
                        this.now = res.data.data[0].id
                        this.CarClassIf.forEach(i => {
                            // this.list[i.id]=[]
                            this.$set(this.list,i.id,[])
                        });
                        ////console.log(this.list)
                    }else if(res.data.code == '-1'){
                        this.$toast.fail(res.data.msg)

                        this.$router.push('/login')
                         deleteCookie('token')
                        //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                 
                        //     if(res.data.code == 0){
                        //         let domain = this.zhuan(res.data.data)
                        //         let url = window.location.origin
                           
                        //         window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
                        //     }
                        // })
                        // window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
                        // this.$router.push({path:"/login",query:{id:this.$route.query.id,ids:this.$route.query.ids}})

                    }else{
                        this.$toast.fail(res.data.msg)
                    }
                    ////console.log(res)
                })
            },
            changHan(item,index) {//点击选择
                this.now = item.id
                this.idx = index
                this.Niname = item.name
            },
            setData() {//移动端完备完备检查
                let examine = {
                    'carNumberPlate':this.$route.query.id,
                    'remark':this.remark,
                    'carClassifyId':this.now,
                    'data':JSON.stringify(this.list[this.now]),
                    'end_of_lifeVehiclesId':this.$route.query.ids
                } 
                
                this.$http.post('/index.php/index/Car_Information/completenessCheckAdd',examine).then(res=>{
                    //  ////console.log(res)
                    if(res.data.code == 0){
                        this.$toast.success('添加成功');
                    }else{
                        this.$toast.fail(res.data.msg);
                    }
                })
            },
            addList() {//添加
                let cope = {
                        name:'',//名称
                        num:'',//数量
                        isDestroy:0,//单选按钮
                    };
                    this.list[this.now].push(cope);
                  
            },
             previousPage(){
                this.$router.go(-1)
            },
        },
    }
</script>
<style lang="scss" scoped> 
    .header {
        padding: 0 20px;
        background-color: cadetblue;
        height: 1rem;
        line-height: 1rem;
        color: white;
        font-size: 0.4rem;
        display: flex;
        position: fixed;
        top: 0;
        z-index: 9;
        width: calc(100% - 40px) ;
        justify-content:space-between;
        span{
            display: inline-block;
        }
        span:nth-child(1){
            width: 30px;
            height: 30px;
            background-image: url(../../assets/u39.png);
            background-size: 100%;
            margin-top: 8px;
        }
    }
    .inspect_main{
        padding: 50px 20px;
        font-size: 20px;
        textarea{
            width: 100%;
            height: 2rem;;
        }
        .btn_list{
  
            .genre{
                // display: inline-block;
                height: 1rem;
                width:48%;
                float: left;
                border:1px solid ;
                margin-left: 4px;
                margin-top: 18px;
                background-color:gray;
                line-height:1rem;
                text-align:center;
                color:white;
                &.cur{
                    background-color:#ff6b37 ;
                    color: white;
                }

            }
        }
        .table{
            width: 100%;
            height: 5rem;
            border:1px solid;
            overflow: scroll;
            margin-top:5rem;
            .icon{
                text-align: center;
                line-height: 2rem;
                font-size: 35px;
            }
            .table_row{
                height: 1rem;
                border-bottom: 1px solid #000;
              
                .table_col_radio{
                    margin-top: 20px;
                    // padding-left: 0.2rem;
                }
            }
            .input_van{
                width: 100%;
                margin-top: 0.17rem;
                border-radius: 6px;
               
            }
        }
    }
    .batton{
        text-align: center;
        margin-top: 2rem;
        .batton_btn{
            width: 40%;
        }
    }
.table_row_col3{
    position: relative;
    margin-top: 5px;
    .cha{
        position: absolute;
        top: 0;
        right:3px;
    }
}
</style>
